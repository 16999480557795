import styles from './AuthPage.module.css';

export default function CreateAccountPage(props) {
    return (
        <div className={styles.main}>
            <div>
                <div>
                    {
                        props.alt ?
                        <div>
                            <img src='./images/logo/no-bg.png' alt=''/>
                        </div> :
                        <div style={{background: "#1C208B"}}>
                            <img src='./images/logo/no-bg-white.png' alt=''/>
                        </div>
                    }
                </div>
            </div>
            <div>
                <h1>{props.title}</h1>
                {props.children}
            </div>
        </div>
    );
}
