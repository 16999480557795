import { useState } from "react";
import styles from "../AuthPage/AuthPage.module.css";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { validateEmail } from "../../utils/validate";
import { useAuth } from "../../hooks/auth";

export default function FP1(props) {
  const navigate = useNavigate();
  const { user, updateData } = useUser();
  const { sendResetEmail } = useAuth();

  const [email, setEmail] = useState(user.email);
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = () => {
    if (email.length === 0) {
      setEmailError("Please enter your email address");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Email invalid. Please enter a valid email address");
      return;
    }

    setLoading(true);
    sendResetEmail(email)
      .then(() => {
        updateData({ ...user, email });
        setLoading(false);
        alert("Password reset email sent!");
      })
      .catch((e) => {
        setLoading(false);
        if (e.code === "auth/user-not-found") {
          setEmailError("user not found");
        } else {
          console.error(e);
          alert(e.message);
          setEmailError("user not found or invalid");
        }
      });
  };

  return (
    <>
      <p className={styles.back} onClick={() => navigate("/")}>
        Home
      </p>
      <form className={styles.form}>
        <div className={emailError !== "" ? styles.error : null}>
          <p>Email Address</p>
          <input
            type="email"
            value={email}
            placeholder="Enter your email address"
            autoComplete="email"
            onChange={(e) => {
              setEmailError("");
              setEmail(e.target.value);
            }}
            ref={(input) => input && emailError !== "" && input.focus()}
          />
          <h6>{emailError}</h6>
        </div>
      </form>
      {loading ? (
        <button className={styles.loader1}></button>
      ) : (
        <button onClick={() => submit()}>Send</button>
      )}
    </>
  );
}
