import AboutUsContent from "../AboutUsContent/AboutUsContent";

export default function WhoAreWe() {
    return (
        <AboutUsContent 
            left={true} 
            title="Who are we?" 
            img="./images/backgrounds/who-are-we.png"
        >
            Welcome to Nile university MSSN, a distinguished educational institution dedicated to providing a comprehensive education that seamlessly integrates both Islamic teachings and secular knowledge. Our school is a place where students can grow intellectually, spiritually, and morally, guided by the principles of Islam We pride ourselves on our diverse and inclusive school community. Our dedicated staff, supportive parents, and motivated students create a vibrant learning environment. Our state-of-the-art facilities provide the perfect setting for students to thrive academically and spiritually.
        </AboutUsContent>
    );
}
