import { Route, Routes} from "react-router-dom";
import FirebaseAuth from "../pages/FirebaseAuth";
import MaintenanceMode from "../components/MaintenanceMode/MaintenanceMode";


export default function MaintenanceRoutes() {
    return (
        <Routes>
            <Route path="/dashboard" element={<FirebaseAuth />} />
            <Route path="/*" element={<MaintenanceMode />} />
        </Routes>
    )
}