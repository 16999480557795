import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/slices/newUser";

export function useUser() {
  let dispatch = useDispatch();
  const user = useSelector((state) => state.newUser.value);

  const updateData = (data) => {
    dispatch(
      setUser({
        ...user,
        ...data,
      })
    );
  };
  return { user, updateData };
}
