import { useNavigate } from 'react-router-dom';
import Activity from '../Activity/Activity';
import styles from './OurActivities.module.css';
import Overlay from '../Overlay/Overlay';
import { useEffect, useState } from 'react';

export default function OurActivites() {

    // const [move, setMove] = useState(true);
    // const [forward, setForward] = useState(1);
    // const [currentWidth, setCurrentWidth] = useState(0);
    // useEffect(() => {
    //     let scroller = document.getElementById('scroller');
    //     let scrollerWidth = scroller.scrollWidth - scroller.clientWidth;
    //     setInterval(() => {
    //         if (move) {
    //             if (currentWidth < 0 || currentWidth > scrollerWidth) setForward(!forward);
    //             if (forward) {
    //                 setCurrentWidth(currentWidth + 1);
    //             } else {
    //                 // if you don't want to continue scroll 
    //                 // clearInterval(interval) use clearInterval
    //                 setCurrentWidth(currentWidth - 1);
    //             }
    //             scroller.scrollTo(currentWidth, 0);
    //         }
    //     }, 50)
    // });

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const closeModal = (e) => {
        if (e.target.classList.contains('modal')) {
            setShowError(false);
        }
    }
    
    useEffect(() => {
        document.body.addEventListener('click', closeModal);

        return function cleanup() {
            window.removeEventListener('click', closeModal);
        } 
    }, []);

    let navigate = useNavigate();

    return (
        <div className={styles.main}>
            <hr/>
            <Overlay show={showError}>
                <div style={{textAlign: "center"}}>
                    {error}
                </div>
            </Overlay>
            <h1 className={styles.heading}>Our Activities</h1>
            <div 
                className={styles.activities}
                id='scroller'
                // style={{ 
                //     position: "relative",
                //     left: `-${offset}px`
                // }}
                // onMouseOver={() => setMove(false)}
                // onMouseOut={() => setMove(true)}
            >
                <Activity 
                    title="Charity" 
                    img="./images/backgrounds/charity.png"
                    cta="Donate Now"
                    action={() => navigate("/home")}
                >
                    We host charity events and programs that provide essential support to the muslim ummah.
                </Activity>
                <Activity 
                    title="Islamiyyah" 
                    img="./images/backgrounds/islamiyya-2.png"
                    cta="Register Now"
                    action={() => navigate("/islamiyya")}
                >
                    We provide Islamiyyah classes to enrich knowledge on Islamic teachings and principles.
                </Activity>
                <Activity 
                    title="Umrah Trip" 
                    img="./images/backgrounds/umrah-trip-2.png"
                    cta="Learn more"
                    action={() => {
                        setError("Please check back some other time as this is not available yet");
                        setShowError(true);
                    }}
                >
                    We plan our yearly Umrah trip to  both Makkah and Madinah to observe voluntary Islamic pilgrimage.
                </Activity>
                <Activity 
                    title="Monthly Lectures" 
                    img="./images/backgrounds/monthly-lecture-2.png"
                    cta="View programs"
                    action={() => navigate("/programs")}
                >
                    We host monthly lectures fostering community engagement and knowledge sharing on diverse topics.
                </Activity>
                <Activity 
                    title="Musābaqah" 
                    img="./images/backgrounds/musabaqah-2.png"
                    cta="Register Now"
                    action={() => {
                        setError("Registration is closed till Ramadān");
                        setShowError(true);
                    }}
                >
                    We host a quranic competition during Ramadān, to test the knowledge of the participants.
                </Activity>
            </div>
            <hr/>
        </div>
    );
}
