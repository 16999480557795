import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import FirebaseAuth from "../pages/FirebaseAuth";
import Profile from "../pages/Profile";
import SignUp from "../pages/SignUp";
import LogIn from "../pages/LogIn";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import Programs from "../pages/Programs";
import Navbar from "../components/Navbar/Navbar";
import IslamiyyaRegistration from "../pages/IslamiyyaRegistration";
import ForgotPassword from "../pages/ForgotPassword";
import AboutUs from "../pages/AboutUs";

export default function MainRoutes({ loggedIn }) {
  const WithNav = () => {
    return (
      <>
        <Navbar loggedIn={loggedIn} />
        <Outlet />
      </>
    );
  };
  const WithoutNav = () => {
    return <Outlet />;
  };
  return (
    <Routes>
      <Route element={<WithoutNav />}>
        <Route
          path="/sign-up"
          element={!loggedIn ? <SignUp /> : <Navigate to="/" />}
        />
        <Route
          path="/login"
          element={!loggedIn ? <LogIn /> : <Navigate to="/" />}
        />
        <Route
          path="/forgot-password"
          element={!loggedIn ? <ForgotPassword /> : <Navigate to="/" />}
        />
        <Route path="/dashboard" element={<FirebaseAuth />} />
      </Route>
      <Route element={<WithNav />}>
        <Route index element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/islamiyya" element={<IslamiyyaRegistration />} />
        <Route path="/programs" element={<Programs />} />
        <Route
          path="/profile"
          element={loggedIn ? <Profile /> : <Navigate to="/" />}
        />
      </Route>
    </Routes>
  );
}
