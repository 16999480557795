import { useState, useEffect } from 'react';
import styles from './Hero.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setTimes } from '../../redux/slices/prayerTimes';
import { getPrayerTimes } from '../../firebase/firestore';

export default function Hero(props) {

    const dispatch = useDispatch();
    let solatTimes = useSelector(state => state.solat.value);

    const todayAsDate = () => {
        return new Date();
    }

    const todayHijri = () => {
        let today = new Intl.DateTimeFormat(
            'en-TN-u-ca-islamic',
            {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            }
        ).format(Date.now());

        let date = today.replace(
            'January', 'Muharram'
        ).replace(
            'February', 'Safar'
        ).replace(
            'March', "Rabī' al-Awwal"
        ).replace(
            'April', "Rabī' ath-Thānī"
        ).replace(
            'May', "Jumādal-Awwal"
        ).replace(
            'June', "Jumādath-Thānī"
        ).replace(
            'July', "Rajab"
        ).replace(
            'August', "Sha'bān"
        ).replace(
            'September', "Ramadān"
        ).replace(
            'October', "Shawwāl"
        ).replace(
            'November', "Dhul-Qa'dah"
        ).replace(
            'December', "Dhul-Hijjah"
        ).split(' ');

        return `${Number(date[1].split(',')[0])} ${date[0]} ${date[2]} AH`
    }

    const dateForm = (time, extend = true) => {
        let today = new Date();

        let day = today.getDate();
        let month = today.getMonth();
        let year = today.getFullYear();
        let hours = time.split(":")[0];
        let minutes = time.split(":")[1];

        if (!extend) {
            return new Date(year, month, day, hours, minutes);
        }

        let prayerTime = new Date(year, month, day, hours, minutes);

        return new Date(prayerTime.getTime() + 10 * 60000);
    }

    const nextPrayer = () => {
        let today = todayAsDate();
        let diff = dateForm(solatTimes.fajr, false) - today;
        if (diff < 0) {
            diff = 24 * 60 * 60000 + diff;
        }
        let next = "Fajr";

        let times = {
            "Dhuhr": solatTimes.dhuhr,
            "Asr": solatTimes.asr,
            "Maghrib": solatTimes.maghrib,
            "Ishā'": solatTimes.isha
        };

        Object.keys(times).forEach(prayer => {
            let time = dateForm(times[prayer], false) - today;
            if (time < diff && time > 0) {
                diff = time;
                next = prayer;
            }
        });

        let seconds = Math.round(diff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        seconds = seconds % 60;
        minutes = minutes % 60;
        return `${next} time in 0${hours} : ${minutes < 10 ? '0' : ''}${minutes} : ${seconds < 10 ? '0' : ''}${seconds}`
    }

    const [time, setTime] = useState(nextPrayer);    
    const[date, setDate] = useState(solatTimes.date || todayHijri());

    useEffect(() => {
        const interval = setInterval(() => setTime(nextPrayer), 1000);
        return () => {
            clearInterval(interval);
        };
    });

    useEffect(() => {
        getPrayerTimes()
            .then((prayerTimes) => {
                if (prayerTimes) {
                    const prayerTime = prayerTimes.today;
                    setDate(prayerTimes.date);
                    dispatch(setTimes({
                        fajr: prayerTime.Fajr,
                        dhuhr: prayerTime.Dhuhr,
                        asr: prayerTime.Asr,
                        maghrib: prayerTime.Maghrib,
                        isha: prayerTime["Isha'a"],
                        date: prayerTimes.date
                    }));
                } else {
                    console.error('Failed to update prayer times')
                }
            })
    }, [dispatch])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={styles.hero}>
            <div className={styles.welcome}>
                <div>
                    <div className={styles.bubble}>
                        <h1>
                            Assalaamu alaykum{props.name}!
                        </h1>
                        <img className={styles.handWave} src='./images/icons/hand-wave.png' alt='' />
                    </div>
                    <p>Welcome to Nile MSSN! A journey of <b>spiritual upliftment</b>, inspired by teachings of the Quran and Sunnah, awaits you to strengthen your īmān and nurture your growth.</p>
                    <div className={styles.welcomeButtons}>
                        <button>Join Our Community</button>
                        <button>{date}</button>
                    </div>
                </div>
                <div>
                    <img src='./images/hero/hero-img.png' alt='' />
                </div>
            </div>
            <div className={styles.prayerTimes}>
                <div>
                    <p className={styles.time}><b>{time}</b></p>
                </div>
                <div>
                    <div className={
                        dateForm(solatTimes.fajr) >= todayAsDate() || todayAsDate() > dateForm(solatTimes.isha) ?
                            styles.nextPrayer :
                            styles.prayer
                    }>
                        <h6>Fajr</h6>
                        <p>{solatTimes.fajr}</p>
                        <img src='./images/icons/star.svg' alt='' className={styles.blueStar}></img>
                        <img src='./images/icons/star.svg' alt='' className={styles.greenStar}></img>
                    </div>
                    <div className={
                        dateForm(solatTimes.dhuhr) >= todayAsDate() && todayAsDate() > dateForm(solatTimes.fajr) ?
                            styles.nextPrayer :
                            styles.prayer
                    }>
                        <h6>Dhuhr</h6>
                        <p>{solatTimes.dhuhr}</p>
                        <img src='./images/icons/star.svg' alt='' className={styles.blueStar}></img>
                        <img src='./images/icons/star.svg' alt='' className={styles.greenStar}></img>
                    </div>
                    <div className={
                        dateForm(solatTimes.asr) >= todayAsDate() && todayAsDate() > dateForm(solatTimes.dhuhr) ?
                            styles.nextPrayer :
                            styles.prayer
                    }>
                        <h6>Asr</h6>
                        <p>{solatTimes.asr}</p>
                        <img src='./images/icons/star.svg' alt='' className={styles.blueStar}></img>
                        <img src='./images/icons/star.svg' alt='' className={styles.greenStar}></img>
                    </div>
                    <div className={
                        dateForm(solatTimes.maghrib) >= todayAsDate() && todayAsDate() > dateForm(solatTimes.asr) ?
                            styles.nextPrayer :
                            styles.prayer
                    }>
                        <h6>Maghrib</h6>
                        <p>{solatTimes.maghrib}</p>
                        <img src='./images/icons/star.svg' alt='' className={styles.blueStar}></img>
                        <img src='./images/icons/star.svg' alt='' className={styles.greenStar}></img>
                    </div>
                    <div className={
                        dateForm(solatTimes.isha) > todayAsDate() && todayAsDate() > dateForm(solatTimes.maghrib) ?
                            styles.nextPrayer :
                            styles.prayer
                    }>
                        <h6>Ishā'</h6>
                        <p>{solatTimes.isha}</p>
                        <img src='./images/icons/star.svg' alt='' className={styles.blueStar}></img>
                        <img src='./images/icons/star.svg' alt='' className={styles.greenStar}></img>
                    </div>
                </div>
            </div>
            <h4>**the prayer times are that of the main mosque at Nile University of Nigeria</h4>
        </div>
    );
}
