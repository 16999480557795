import { useEffect, useState } from 'react';
import Overlay from '../Overlay/Overlay';
import styles from './DownloadImage.module.css';
import domtoimage from 'dom-to-image-more';

export default function DownloadImage(props) {

    const [background, setBackground] = useState(Math.floor(Math.random() * 16) + 1);
    const [showBackgrounds, setShowBackgrounds] = useState(false);
    const [imageForms, setImageForms] = useState({
        "title": props.hasOwnProperty("title"),
        "normal": props.hasOwnProperty('arabic'),
        "transliteration": props.hasOwnProperty('transliteration'),
        "translation": props.hasOwnProperty('translation')
    });

    const [imgWidth, setImgWidth] = useState(0);
    useEffect(() => {
        const width = window.innerWidth || 
            document.documentElement.clientWidth || 
            document.body.clientWidth;
        setImgWidth(width * 0.9 - 65);
    }, [imgWidth]);

    const printToFile = () => {
        let div = document.getElementById(props.imageId);
        let scale = 2.5;
        domtoimage.toCanvas(div, {
            width: div.clientWidth * scale,
            height: div.clientHeight * scale,
            style: { transform: 'scale('+scale+')', transformOrigin: 'top left'}
        }).then(canvas => {
            try {
                var myImage = canvas.toDataURL();
            } catch {
                // catch errors
            }
            downloadURI(myImage, `${Date.now()}.png`);
        }).catch(err => {
            // Handle error
        });
    }

    const downloadURI = (uri, name) => {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        try {
            link.click();
        } catch {
            // Tell them there's no internet
        }
        //after creating link you should delete dynamic link
        //clearDynamicLink(link); 
    }

    return (
        <Overlay show={props.show}>
            <div style={{paddingTop: showBackgrounds ? "5px" : "30px"}}>
                {
                    showBackgrounds ?
                    <div>
                        <div>
                            <p className={styles.heading}>Select Background</p>
                        </div>
                        <div 
                            className={styles.grid}
                            style={{'--val': imgWidth}}
                        >
                            {[...Array(16).keys()].map((n) => {
                                return (
                                    <div 
                                        key={n}
                                        className={
                                            background === n + 1 ? 
                                            `pattern${n + 1} ${styles.selected}` : 
                                            `pattern${n + 1}`
                                        } 
                                        onClick={() => {
                                            setBackground(n + 1);
                                            setShowBackgrounds(false);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div> :
                    <div>
                        <div
                            className={`pattern${background} ${styles.image}`}
                            style={{'--val': imgWidth}}
                        >
                            <div className={styles.content}>
                                <div>
                                    <img src='./images/logo/1.png' alt=''/>
                                </div>
                                <div>
                                    {
                                        imageForms['title'] && 
                                        <p className={styles.title}>
                                            {props.title}
                                        </p>
                                    }
                                    {
                                        imageForms['normal'] && 
                                        <p className={styles.arabic}>
                                            {props.arabic}
                                        </p>
                                    }
                                    {
                                        imageForms['transliteration'] && 
                                        <p className={styles.english}>
                                            {props.transliteration}
                                        </p>
                                    }
                                    {
                                        imageForms['translation'] && 
                                        <p className={styles.english}>
                                            {props.translation}
                                        </p>
                                    }
                                </div>
                                <div>
                                    <p className={styles.reference}><i><b>{props.reference}</b></i></p>
                                </div>
                            </div>
                        </div>
                        <div
                            id={props.imageId} 
                            className={`pattern${background} ${styles.image}`}
                            style={{position: "absolute", zIndex: -999, left: "calc(100% + 100px)"}}
                        >
                            <div className={styles.content}>
                                <div>
                                    <img src='./images/logo/1.png' alt=''/>
                                </div>
                                <div>
                                    {
                                        imageForms['title'] && 
                                        <p className={styles.title}>
                                            {props.title}
                                        </p>
                                    }
                                    {
                                        imageForms['normal'] && 
                                        <p className={styles.arabic}>
                                            {props.arabic}
                                        </p>
                                    }
                                    {
                                        imageForms['transliteration'] && 
                                        <p className={styles.english}>
                                            {props.transliteration}
                                        </p>
                                    }
                                    {
                                        imageForms['translation'] && 
                                        <p className={styles.english}>
                                            {props.translation}
                                        </p>
                                    }
                                </div>
                                <div>
                                    <p className={styles.reference}><i><b>{props.reference}</b></i></p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.options}>
                            <input 
                                type='checkbox' 
                                checked={imageForms['normal']} 
                                onChange={() => setImageForms(
                                    {...imageForms, 'normal': !imageForms['normal']}
                                )}
                            />
                            <span>Arabic Text</span>
                            {
                                props.hasOwnProperty('transliteration') && (
                                    <>
                                        <input 
                                            type='checkbox' 
                                            checked={imageForms['transliteration']} 
                                            onChange={() => setImageForms(
                                                {
                                                    ...imageForms, 
                                                    'transliteration': !imageForms['transliteration']
                                                }
                                            )}
                                        />
                                        <span>Transliteration</span>
                                    </>
                                )
                            }
                            <input 
                                type='checkbox' 
                                checked={imageForms['translation']}
                                onChange={() => setImageForms(
                                    {...imageForms, 'translation': !imageForms['translation']}
                                )}
                            />
                            <span>Translation</span>
                        </div>
                        <div className={styles.buttons}>
                            <button onClick={() => setShowBackgrounds(true)}>Change BG</button>
                            <button onClick={() => {
                                printToFile();
                                // Close modal
                            }}>Download</button>
                        </div>
                    </div>
                }
            </div>
        </Overlay>
    );
}
