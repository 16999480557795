import { useState } from "react";
import styles from "../AuthPage/AuthPage.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../redux/slices/newUser";
import { addUser, checkUser, updateUser } from "../../firebase/firestore";
import { hashCompare } from "../../utils/compute";
import { useAuth } from "../../hooks/auth";
import { useUser } from "../../hooks/useUser";
import { useNext } from "../../hooks/nextPage";

export default function LI(props) {
  let dispatch = useDispatch();

  const { user, updateData } = useUser();
  const { login, signInWithGoogle } = useAuth();

  const navigate = useNavigate();

  const [remember, setRemember] = useState(user.rememberMe);
  const [email, setEmail] = useState(user.email && remember ? user.email : "");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [pwError, setPwError] = useState("");

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const nextPage = useNext("/sign-up", { signup: "final" });

  const submit = async () => {
    setEmailError("");
    setPwError("");

    if (email.length === 0) {
      setEmailError("Please enter your email");
      return;
    }

    if (password.length === 0) {
      setPwError("Please enter a password");
      return;
    }

    if (password.length < 8) {
      setPwError("Your password should be at least 8 characters");
      return;
    }

    // Check database and proceed to home page
    setLoading(true);
    try {
      const { userData: userDb } = await checkUser(email);
      const verified = await hashCompare(password, userDb.passwordHash);
      if (verified) {
        const userDetails = await login(email, password, remember);
        if (userDetails) {
          const userData = {
            ...user,
            ...userDb,
            provider: "email",
            rememberMe: remember,
          };
          updateData(userData);
          await updateUser(userData.uid, userData);
          dispatch(setLoggedIn());
          navigate("/");
        } else {
          setEmailError("Wrong email or password!");
        }
      } else {
        setEmailError("Wrong email or password!");
      }
    } catch (error) {
      console.error(error);
      setEmailError("Invalid email address or password.");
    } finally {
      setLoading(false);
    }
  };

  const googleLogin = async () => {
    let userData;
    setLoading(true);
    try {
      const newUser = await signInWithGoogle(remember);
      if (newUser) {
        const { exists, userData: userDb } = await checkUser(newUser.email);
        if (exists) {
          userData = {
            ...user,
            ...userDb,
            provider: "google",
            rememberMe: remember,
            photoURL: newUser.photoURL,
          };
          updateData(userData);
          await updateUser(userData.uid, userData);
          dispatch(setLoggedIn());
        } else {
          userData = {
            ...user,
            fullName: newUser.displayName,
            email: newUser.email,
            uid: newUser.uid,
            photoURL: newUser.photoURL,
            provider: "google",
            rememberMe: remember,
            verified: true,
            userRole: "USER",
          };
          await addUser(newUser.uid, userData);
          updateData(userData);
          nextPage();
        }
      } else {
        throw new Error("Missing email or password.");
      }
    } catch (e) {
      if (e.code === "auth/email-already-in-use") {
        setEmailError("Email already in use");
      } else if (e.code === "auth/popup-blocked") {
        alert(
          "Popup blocked! please try again.\nIf this error still remain check your browser settings and enable popup."
        );
      } else if (e.message !== "user-already-exists") {
        console.error(e);
        alert(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p className={styles.back} onClick={() => navigate("/")}>
        Back
      </p>
      <form className={styles.form}>
        <div className={emailError !== "" ? styles.error : null}>
          <p>Email</p>
          <input
            type="email"
            value={email}
            placeholder="Enter your email address"
            autoComplete="email"
            onChange={(e) => {
              setEmailError("");
              setPwError("");
              setEmail(e.target.value);
            }}
            ref={(input) => input && emailError !== "" && input.focus()}
          />
          <h6>{emailError}</h6>
        </div>
        <div className={pwError !== "" ? styles.error : null}>
          <p>Password</p>
          <div className={styles.password}>
            <input
              type={visible ? "text" : "password"}
              value={password}
              placeholder="Enter your password"
              autoComplete="password"
              onChange={(e) => {
                setEmailError("");
                setPwError("");
                setPassword(e.target.value);
              }}
              ref={(input) => input && pwError !== "" && input.focus()}
            />
            <div onClick={() => setVisible(!visible)}>
              {visible ? (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/hide-password.png"
                  alt="visible"
                />
              ) : (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/show-password.png"
                  alt="visible"
                />
              )}
            </div>
          </div>
          <h6>{pwError}</h6>
        </div>
        <div className={styles.space}>
          <p
            className={styles.condition}
            style={{ margin: "0", fontWeight: "400", color: "#302B27" }}
          >
            {remember ? (
              <img
                src="./images/icons/checkbox-checked.svg"
                alt=""
                onClick={() => setRemember(false)}
              />
            ) : (
              <img
                src="./images/icons/checkbox-unchecked.svg"
                alt=""
                style={{
                  height: "18px",
                  marginLeft: "3.5px",
                  marginRight: "3.5px",
                }}
                onClick={() => setRemember(true)}
              />
            )}
            Remember me
          </p>
          <a href="/forgot-password" style={{ textDecoration: "none" }}>
            Forgot Password?
          </a>
        </div>
      </form>
      {loading ? (
        <button className={styles.loader2}></button>
      ) : (
        <>
          <button onClick={() => submit()}>Login</button>
          <button className={styles.google} onClick={() => googleLogin()}>
            <img src="./images/icons/google.svg" alt="google-icon"></img>Login
            in with Google
          </button>
        </>
      )}
      <p>
        Don't have an account? <a href="/sign-up">Create Account</a>
      </p>
    </>
  );
}
