import bcrypt from 'bcryptjs';


export async function hash(string) {
  const saltRounds = 10; // Adjust salt rounds for desired security level
  const hash = await bcrypt.hash(string, saltRounds);
  return hash;
}

export async function hashCompare(string, hash) {
  if (!hash || !string) throw new Error("Error validating password")
  return bcrypt.compare(string, hash);
}

/**
 * Generates a random 4-digit OTP.
 * 
 * @returns {string} The randomly generated 4-digit OTP.
 */
export const generateOTP = () => {
  // returns a random 4-digit as OTP
  return Math.floor(1000 + Math.random() * 9000).toString();
}

export const toMins = (time) => {
  let seconds = time % 60;
  let minutes = Math.floor(time / 60);
  return `${minutes} : ${seconds < 10 ? '0' : ''}${seconds}`
}
