import { useState } from "react";
import styles from "./ProfileField.module.css";
import depts from "../../../data/departments";
// import PDD from "../ProfileDropDown/PDD";
import { validateEmail } from "../../../utils/validate";
import Select from "react-select";

export default function ProfileField({ user: userProps, update, edit }) {
  const [user, setUser] = useState(userProps); // eslint-disable-next-line
  const [error, setError] = useState("");

  const fields = [
    { key: "Name: ", value: user.fullName, type: "fullName" },
    { key: "Email: ", value: user.email, type: "email" },
    { key: "Student ID: ", value: user.schoolId, type: "schoolId" },
    { key: "Phone: ", value: user?.phone || "", type: "phone" },
    { key: "Department: ", value: user.dept, type: "dept" },
    { key: "Level: ", value: user.level, type: "level" },
  ];
  console.log(user.fullName);
  // Define field types for cleaner mapping
  const fieldTypes = {
    displayName: "name",
    email: "email",
    schoolId: "text",
    phone: "text",
    dept: "text",
    level: "text",
  };

  const arrayToOptions = (items) => {
    return items.map((item) => ({ value: item, label: item }));
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: "black",
      width: "95%",
      textAlign: "left",
      minHeight: "26px",
      backgroundColor: state.isFocused ? "#f0f0f0" : "white", // Set background color based on focus
    }),
    options: (provided, selected) => ({
      ...provided,
      width: "95%",
      textAlign: "left",
      background: selected ? "#1c208b" : "white", // Set selected option background and text color
      color: selected ? "white" : "black",
    }),
  };

  const validate = (data, field) => {
    if (!validateEmail(data.email))
      return { isValid: false, errorField: "Email" };
    if (
      data.phone &&
      (data.phone.replace(/\s+/g, "").length < 11 ||
        data.phone.toString().charAt(0) !== "0" ||
        !["7", "8", "9"].includes(data.phone.toString().charAt(1)) ||
        !["0", "1"].includes(data.phone.toString().charAt(2)))
    )
      return { isValid: false, errorField: "Phone" };
    return { isValid: true, errorField: "" };
  };
  // Handle potential missing data in props
  const handleMissingData = (dataField) => {
    if (!user?.[dataField]) {
      return edit ? "" : "None";
    }
    return user?.[dataField];
  };
  // Handle potential missing data in props
  const handleChange = (field) => {
    if (["dept", "level"].includes(field)) {
      return (option) => {
        const data = { ...user, [field]: option.label };
        setUser(data);
        update(data);
      };
    }
    return (e) => {
      const data = { ...user, [field]: e.target.value };
      const { isValid, errorField } = validate(data, field);
      setUser(data);
      if (isValid) {
        update(data);
      } else {
        setError(`${errorField} is invalid`);
      }
    };
  };

  // Optimized field rendering with concise logic
  const renderField = (field, index) => (
    <div key={index}>
      <strong>{field.key}</strong>
      {edit ? (
        ["dept", "level"].includes(field.type) ? (
          // <PDD
          //   prompt={field.value === "" ? "--Select--" : field.value}
          //   options={
          //     field.type === "level" ? [100, 200, 300, 400, 500, 600] : depts
          //   }
          //   onSelect={handleChange(field.type)}
          // />
          <Select
            placeholder={field.value === "" ? "--Select--" : field.value}
            options={
              field.type === "level"
                ? arrayToOptions([100, 200, 300, 400, 500, 600])
                : arrayToOptions(depts)
            }
            styles={customStyles}
            onChange={handleChange(field.type)}
          />
        ) : (
          <input
            id={field.type}
            type={fieldTypes[field.type]}
            value={handleMissingData(field.type)} // Ensure value even for missing data
            autoComplete={fieldTypes[field.type]}
            onChange={handleChange(field.type)}
          />
        )
      ) : (
        <div>{handleMissingData(field.type)}</div>
      )}
    </div>
  );

  return <div className={styles.values}>{fields.map(renderField)}</div>;
}
