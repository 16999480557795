import styles from "./ProfileButton.module.css";

export default function ProfileButton({ loading, edit, handleClick, handleClose }) {
  return (
    <div className={styles.btn}>
      <button disabled={loading} onClick={handleClick}>
        {loading ? (
          <div className={styles.loader1}></div>
        ) : edit ? (
          "Update"
        ) : (
          "Edit"
        )}
      </button>
      {edit && (
        <button className={styles.cancel} onClick={handleClose}>
          Cancel
        </button>
      )}
    </div>
  );
}
