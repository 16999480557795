import { useState } from "react";
import styles from "./profile.module.css";
import ProfileField from "../ProfileField/ProfileField";
import ProfileButton from "../ProfileButton/ProfileButton";
import { useUser } from "../../../hooks/useUser";
import { updateUser } from "../../../firebase/firestore";

function ProfileContent() {
  const { user: userRedux, updateData } = useUser();

  const [user, setUser] = useState(userRedux);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (!edit) {
      setEdit(true);
    } else {
      setLoading(true);
      setEdit(false);
      updateUser(user.uid, user)
        .then(() => {
          updateData(user);
          setUser(user)
        })
        .catch((e) => {
          setEdit(true);
          console.log(e.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleClose = () => {
    setEdit(false);
    setUser(userRedux);
  };

  return (
    <div className={styles.main}>
      <div className={styles.description}>
        {!user ? (
          <h2>Please log in to view your profile.</h2>
        ) : (
          <>
            <div>
              <img
                src={user.photoURL || "./images/dummy-client/1.png"}
                alt="User Avatar"
              />
              <h2>{userRedux.fullName || "Anonymous User"}</h2>
              <div className={styles.line}>
                <div></div>
              </div>
            </div>
            <div>
              <ProfileField
                user={user}
                edit={edit}
                update={(data) => setUser(data)}
              />
              {user && (
                <div className={styles.bio}>
                  <h3>Bio</h3>
                  <p>{user?.bio || "No bio available"}</p>
                </div>
              )}
            </div>
            <ProfileButton
              loading={loading}
              edit={edit}
              handleClick={handleClick}
              handleClose={handleClose}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ProfileContent;
