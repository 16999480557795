import { combineReducers, configureStore } from '@reduxjs/toolkit';
import verseReducer from './slices/verse';
import hadithReducer from './slices/hadith';
import duaReducer from './slices/dua';
import prayerTimesReducer from './slices/prayerTimes';
import newUserReducer from './slices/newUser';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const rootReducer = combineReducers({
  newUser: newUserReducer,
  verse: verseReducer,
  hadith: hadithReducer,
  dua: duaReducer,
  solat: prayerTimesReducer,
})


const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  blacklist: ['verse', 'hadith', 'dua'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store)

export default store;
