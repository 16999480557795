import { useState, useEffect } from 'react';
import styles from './DailyDua.module.css';
import { useSelector } from 'react-redux';
import DownloadImage from '../DownloadImage/DownloadImage';

export default function DailyDua() {
    const [forms, setForms] = useState({
        "normal": true,
        "transliteration": false,
        "translation": true
    });
    const [showFilter, setShowFilter] = useState(false);

    let dua = useSelector(state => state.dua.value);

    const [showDownload, setShowDownload] = useState(false);

    const closeModal = (e) => {
        if (e.target.classList.contains('modal')) {
            setShowDownload(false);
        }
    }

    useEffect(() => {
        document.body.addEventListener('click', closeModal);

        return function cleanup() {
            window.removeEventListener('click', closeModal);
        } 
    }, []);

    return (
        <div className={styles.main}>
            <DownloadImage
                show={showDownload}
                imageId={'duaImage'}
                title={dua.title.toUpperCase()}
                arabic={dua.arabic.replace(/و۟/g, 'و').replace(/ا۟/g, 'ا')}
                translation={dua.english}
                transliteration={dua.transliteration}
                reference={dua.source}
            />
            <div>
                <h1>Daily Dhikr/Du'ā</h1>
            </div>
            <div className={styles.dua}>
                <div>
                    <div>
                        <p className={styles.title}>{dua.title}</p>
                    </div>
                    <div className={styles.duaContent}>
                        {forms['normal'] && <p className={styles.arabic}>{dua.arabic.replace(/و۟/g, 'و').replace(/ا۟/g, 'ا')}</p>}
                        {forms['transliteration'] && <p className={styles.english}><i>{dua.transliteration}</i></p>}
                        {forms['translation'] && <p className={styles.english}>{dua.english}</p>}
                    </div>
                    <div>
                        <p className={styles.source}><i>{dua.source}</i></p>
                    </div>
                    <div>
                        <button>Save Du'ā</button>
                        <button onClick={() => setShowDownload(true)}>Generate Image</button>
                    </div>
                    <img 
                        src='./images/icons/filter.svg' 
                        alt='' 
                        className={styles.filter} 
                        onClick={() => setShowFilter(!showFilter)}
                    />
                    {
                        showFilter &&
                        <div className={styles.filters}>
                            <div>
                                <input 
                                    type='checkbox' 
                                    checked={forms['normal']} 
                                    onChange={() => {
                                        setForms({...forms, 'normal': !forms['normal']});
                                    }}
                                />
                                Arabic Text
                            </div>
                            <div>
                                <input 
                                    type='checkbox' 
                                    checked={forms['transliteration']} 
                                    onChange={() => {
                                        setForms({...forms, 'transliteration': !forms['transliteration']});
                                    }}
                                />
                                Transliteration
                            </div>
                            <div>
                                <input 
                                    type='checkbox' 
                                    checked={forms['translation']} 
                                    onChange={() => {
                                        setForms({...forms, 'translation': !forms['translation']});
                                    }}
                                />
                                Translation
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <img src='./images/backgrounds/dua.png' alt='' />
                </div>
            </div>
        </div>
    );
}
