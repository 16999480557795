import styles from './AboutUsContent.module.css';

export default function AboutUsContent(props) {
    return (
        <div className={props.left ? styles.left : styles.right}>
            <div>
                <img src={props.img} alt='' />
            </div>
            <div>
                <h2>{props.title}</h2>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    );
}
