import { useState } from "react";
import styles from "../AuthPage/AuthPage.module.css";
import DropDown from "../DropDown/DropDown";
import depts from "../../data/departments";
import { setLoggedIn } from "../../redux/slices/newUser";
import { useUser } from "../../hooks/useUser";
import { useDispatch } from "react-redux";
import { updateUser } from "../../firebase/firestore";
import { useAuth } from "../../hooks/auth";

export default function CA3(props) {
  const dispatch = useDispatch();
  const {user, updateData} = useUser();
  const { logout } = useAuth();

  const [agreed, setAgreed] = useState(true);
  const [id, setId] = useState(user.schoolId);
  const [dept, setDept] = useState(user.dept);
  const [level, setLevel] = useState(user.level);

  const [idError, setIdError] = useState("");
  const [deptError, setDeptError] = useState("");
  const [levelError, setLevelError] = useState("");

  const [loading, setLoading] = useState(false);

  const submit = () => {
    setIdError("");
    setDeptError("");
    setLevelError("");

    // Check school ID
    if (id.toString().length === 0) {
      setIdError("Please enter your phone number");
      return;
    }

    if (id.toString().length < 8) {
      setIdError("Please crosscheck the number you entered");
      return;
    }

    if (!["0", "1", "2"].includes(id.toString().charAt(0))) {
      setIdError("Please crosscheck the number you entered");
      return;
    }

    if (dept.length === 0) {
      setDeptError("Please select your department");
      return;
    }

    if (level.length === 0) {
      setLevelError("Please select your level");
      return;
    }

    const userData = {
      ...user,
      dept: dept,
      level: level,
      schoolId: id,
      notifications: agreed,
      userRole: "STUDENT",
    };

    setLoading(true);
    updateUser(user.uid, { ...userData })
      .then(() => {
        updateData(userData);
        dispatch(setLoggedIn());
      })
      .catch((error) => {
        logout().then(() => {
          console.error(error);
          alert(error.message);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form className={styles.form}>
        <h3>School Information (3/3)</h3>
        <div className={idError !== "" ? styles.error : null}>
          <p>School ID</p>
          <input
            type="tel"
            id="student_id"
            value={id}
            placeholder="Enter your school ID number"
            onChange={(e) => {
              setIdError("");
              let number = e.target.value
                ?.replace(/\s+/g, "")
                .replace(/[^0-9]/g, "")
                .replace(/(\..*?)\..*/g, "$1");
              setId(number);
            }}
            ref={(input) => input && idError !== "" && input.focus()}
          />
          <h6>{idError}</h6>
        </div>
        <div className={deptError !== "" ? styles.error : null}>
          <p>Department</p>
          <DropDown
            prompt={dept === "" ? "Select your department" : dept}
            options={depts}
            onSelect={setDept}
            onClick={() => setDeptError("")}
          />
          <h6>{deptError}</h6>
        </div>
        <div className={levelError !== "" ? styles.error : null}>
          <p>Level</p>
          <DropDown
            prompt={level === "" ? "Select your level" : level}
            options={[100, 200, 300, 400, 500, 600]}
            onSelect={setLevel}
            onClick={() => setLevelError("")}
            height={150}
          />
          <h6>{levelError}</h6>
        </div>
        <p className={styles.condition}>
          {agreed ? (
            <img
              src="./images/icons/checkbox-checked.svg"
              alt=""
              onClick={() => setAgreed(false)}
            />
          ) : (
            <img
              src="./images/icons/checkbox-unchecked.svg"
              alt=""
              style={{
                height: "18px",
                marginLeft: "3.5px",
                marginRight: "3.5px",
              }}
              onClick={() => setAgreed(true)}
            />
          )}
          I wish to receive mails about upcoming Nile MSSN events
        </p>
      </form>
      {loading ? (
        <button className={styles.loader1}></button>
      ) : (
        <>
          <button onClick={() => submit()}>Done</button>
        </>
      )}
    </>
  );
}
