import { useEffect, useState } from 'react';
import styles from './DailyQuran.module.css';
import { useSelector } from 'react-redux';
import DownloadImage from '../DownloadImage/DownloadImage';

export default function DailyQuran() {
    const [forms, setForms] = useState({
        "normal": true,
        "translation": true
    });
    const [showFilter, setShowFilter] = useState(false);
    const [showDownload, setShowDownload] = useState(false);

    let verse = useSelector(state => state.verse.value);

    const closeModal = (e) => {
        if (e.target.classList.contains('modal')) {
            setShowDownload(false);
        }
    }

    useEffect(() => {
        document.body.addEventListener('click', closeModal);

        return function cleanup() {
            window.removeEventListener('click', closeModal);
        } 
    }, []);

    return (
        <div>
            <DownloadImage 
                show={showDownload} 
                imageId={'quranImage'}
                arabic={verse.arabic.replace(/و۟/g, 'و').replace(/ا۟/g, 'ا')}
                translation={verse.translation}
                reference={`Surah ${verse.surah} Verse ${verse.verse}`}
            />
            <div className={styles.main}>
                <div>
                    <h1>Daily Qur'an</h1>
                </div>
                <div>
                    <div className={styles.quranImage}>
                        <img src='./images/backgrounds/quran2.png' alt=''/>
                    </div>
                    <div className={styles.verse}>
                        <div>
                            <div>
                                <p className={styles.surah}>Surah {verse.surah} Verse {verse.verse}</p>
                            </div>
                            <div>
                                {forms['normal'] && <p className={styles.arabic}>{verse.arabic.replace(/و۟/g, 'و').replace(/ا۟/g, 'ا')}</p>}
                                {forms['translation'] && <p className={styles.english}>{verse.translation}</p>}
                            </div>
                            <div>
                                <button>Save Verse</button>
                                <button onClick={() => setShowDownload(true)}>Generate Image</button>
                            </div>
                            <img 
                                src='./images/icons/filter.svg' 
                                alt='' 
                                className={styles.filter} 
                                onClick={() => setShowFilter(!showFilter)}
                            />
                            {
                                showFilter &&
                                <div className={styles.filters}>
                                    <div>
                                        <input 
                                            type='checkbox' 
                                            checked={forms['normal']} 
                                            onChange={() => {
                                                setForms({...forms, 'normal': !forms['normal']});
                                            }}
                                        />
                                        Arabic Text
                                    </div>
                                    <div>
                                        <input 
                                            type='checkbox' 
                                            checked={forms['translation']} 
                                            onChange={() => {
                                                setForms({...forms, 'translation': !forms['translation']});
                                            }}
                                        />
                                        Translation
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <p style={{display: "none"}}>
                <a 
                    href="https://iconscout.com/icons/filter" 
                    className="text-underline font-size-sm" 
                >
                    Filter
                </a> 
                by 
                <a 
                    href="https://iconscout.com/contributors/rengised" 
                    className="text-underline font-size-sm"
                >
                    Alex Martynov
                </a> 
                on 
                <a 
                    href="https://iconscout.com" 
                    className="text-underline font-size-sm"
                >
                    IconScout
                </a>
            </p>
        </div>
    );
}
