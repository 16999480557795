import AboutUsContent from "../AboutUsContent/AboutUsContent";

export default function OurContact() {
    return (
        <AboutUsContent left={true} title="Our Contact" img="./images/backgrounds/our-contact.png">
            We are here to assist you with any inquiries or information you may need. Please feel free to walk into any of the mosques in the school environment. 
            <ol style={{margin: "0 15px"}}>
                <li>
                    Contact Information:
                    <ul style={{margin: "0 20px"}}>
                        <li>School's physical address.</li>
                        <li>Phone number(s).</li>
                        <li>Email address(es)..</li>
                    </ul>
                </li>
                <li>
                    Social Media Links:
                    <ul style={{margin: "0 20px"}}>
                        <li>Whatsapp</li>
                        <li>Instagram</li>
                        <li>Facebook</li>
                        <li>X</li>
                    </ul>
                </li>
            </ol>
        </AboutUsContent>
    );
}
