import React from 'react'

function NotFound() {
  return (
    <div style={{color: "black", minHeight: "calc(100vh - 80px)", backgroundColor: "#9191911a"}}>
      <h1 style={{paddingTop : "80px"}}>404 Not Found!</h1>
      <p style={{textAlign: "center"}}>Subhanallah... Page not Found.</p>
    </div>
  )
}

export default NotFound;