import { useSelector } from 'react-redux';
import styles from './DailyHadith.module.css';
import DownloadImage from '../DownloadImage/DownloadImage';
import { useState, useEffect } from 'react';

export default function DailyHadith() {
    let hadith = useSelector(state => state.hadith.value);
    
    const [showDownload, setShowDownload] = useState(false);

    const closeModal = (e) => {
        if (e.target.classList.contains('modal')) {
            setShowDownload(false);
        }
    }

    useEffect(() => {
        document.body.addEventListener('click', closeModal);

        return function cleanup() {
            window.removeEventListener('click', closeModal);
        } 
    }, []);

    return (
        <div className={styles.main}>
            <DownloadImage
                show={showDownload}
                imageId={'hadithImage'}
                arabic={hadith.arabic.replace(/و۟/g, 'و').replace(/ا۟/g, 'ا')}
                translation={hadith.english}
                reference={hadith.source}
            />
            <div>
                <h1 className={styles.blue}>Daily Hadith</h1>
            </div>
            <div className={styles.hadith}>
                <div>
                    <img src='./images/backgrounds/hadith.png' alt='' />
                </div>
                <div>
                    <div className={styles.hadithContent}>
                        <p className={styles.arabic}>{hadith.arabic.replace(/و۟/g, 'و').replace(/ا۟/g, 'ا')}</p>
                        <p className={styles.english}>{hadith.english}</p>
                        <p className={styles.source}><i>{hadith.source}</i></p>
                    </div>
                    <div>
                        <button>Save Hadith</button>
                        <button onClick={() => setShowDownload(true)}>Generate Image</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
