import OurContact from "../components/OurContact/OurContact";
import OurGoal from "../components/OurGoal/OurGoal";
import OurService from "../components/OurService/OurService";
import WhoAreWe from "../components/WhoAreWe/WhoAreWe";
import styles from "../components/AboutUsContent/AboutUsContent.module.css";
import { useEffect } from "react";

export default function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div style={{marginBottom: "135px"}}>
            <h1 className={styles.heading}>About Us</h1>
            <WhoAreWe/>
            <OurGoal/>
            <OurContact/>
            <OurService/>
        </div>
    );
}
