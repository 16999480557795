import { useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom';
import CA1 from "../components/CA1/CA1";
import CA2 from "../components/CA2/CA2";
import CA3 from "../components/CA3/CA3";
import AuthPage from "../components/AuthPage/AuthPage";

export default function SignUp(props) {

    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const [page, setPage] = useState(1);

    useEffect(() => {
        const signupValue = queryParams.get('signup');
        if (['final', 'otp'].includes(signupValue)) {
          setPage(signupValue === 'final' ? 3 : 2);
        } else {
          setPage(1);
        }
      }, [queryParams]);

    return (
        <AuthPage title="Create Account" alt={true}>
            {
                page === 1 ?
                <CA1 /> :
                page === 2 ?
                <CA2 back={() => setPage(1)}/>:
                <CA3 back={() => setPage(2)} />
            }
        </AuthPage>
    );
}
