import AuthPage from "../components/AuthPage/AuthPage";
import LI from "../components/LI/LI";

export default function LogIn(props) {

    return (
        <AuthPage title="Welcome Back">
            <LI />
        </AuthPage>
    );
}
