import styles from './Activity.module.css';

export default function Activity(props) {
    return (
        <div className={styles.left}>
            <div>
                <img loading="lazy" src={props.img} alt='' />
            </div>
            <div>
                <h2>{props.title}</h2>
                <p>
                    {props.children}
                </p>
                <button onClick={() => props.action()}>{props.cta}</button>
            </div>
        </div>
    );
}
