import { useCallback, useEffect, useState } from "react";
import styles from "./IslamiyyaForm.module.css";
import DropDown from "../DropDown/DropDown";
import depts from "../../data/departments";
import Overlay from "../Overlay/Overlay";
import { useUser } from "../../hooks/useUser";
import { registerIslamiyyaStudent } from "../../firebase/firestore";
import { validateEmail } from "../../utils/validate";
import { usePaystackPayment } from "react-paystack";

export default function IslamiyyaForm(props) {
  const { user, updateData } = useUser();
  const reference = new Date().getTime().toString();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dept, setDept] = useState("");
  const [level, setLevel] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(user.email);
  const [category, setCategory] = useState("");
  const [hizb, setHizb] = useState();
  const [recitation, setRecitation] = useState();

  const [showError, setShowError] = useState(false);
  const [fNameError, setFNameError] = useState("");
  const [lNameError, setLNameError] = useState("");
  const [deptError, setDeptError] = useState("");
  const [levelError, setLevelError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");

  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const today = new Date();
  const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${
    today.getMonth() + 1
  }/${today.getFullYear()}`;

  const config = {
    reference,
    amount: 300000,
    publicKey: "pk_test_45a5dd00ce9fe2af7911db22d604766e54956beb",
  };

  const initializePayment = usePaystackPayment(config);

  const closeModal = useCallback(
    (e) => {
      if (e.target.classList.contains("modal") && !loading) {
        setShowError(false);
      }
    },
    [loading]
  );

  useEffect(() => {
    document.body.addEventListener("click", closeModal);

    return function cleanup() {
      window.removeEventListener("click", closeModal);
    };
  }, [closeModal]);

  // const resetForm = () => {
  //   setFirstName("");
  //   setLastName("");
  //   setDept("");
  //   setLevel("");
  //   setPhone("");
  //   setCategory("");
  // };

  const onSuccess = async (reference) => {
    setLoading(true);
    setError("Loading...");
    setShowError(true);
    const formData = {
      firstName,
      lastName,
      dept,
      level,
      phone: phone.trim().split(" ").join(""),
      email,
      category,
      hizb,
      recitation,
      reference,
      date: formattedDate,
    };
    try {
      await registerIslamiyyaStudent(user.uid, formData);
      setError("Islamiyya Registration Successful!");
      updateData({ ...user, islamiyya: formData });
      // resetForm();submitForm();
    } catch (error) {
      setError("An error occurred. Please try again");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const onClose = async () => {
    setError("Registration Cancelled!");
    setShowError(true);
  };

  const submitForm = async () => {
    if (!user.uid) {
      setError("You must be logged in to register");
      setShowError(true);
      return;
    }

    if (firstName.length === 0) {
      setFNameError("Enter your first name");
      return;
    }

    if (lastName.length === 0) {
      setLNameError("Enter your last name");
      return;
    }

    if (dept.length === 0) {
      setDeptError("Select your department");
      setError("You have not selected your department.");
      setShowError(true);
      return;
    }

    if (level.length === 0) {
      setLevelError("Select your level");
      setError("You have not selected your level.");
      setShowError(true);
      return;
    }

    if (phone.length === 0) {
      setPhoneError("Enter your phone number");
      return;
    }

    if (email.length === 0) {
      setEmailError("Enter your email address");
      return;
    }

    if (phone.replace(/\s+/g, "").length < 11) {
      setPhoneError("Phone number must be 11 digits");
      return;
    }

    if (
      phone.toString().charAt(0) !== "0" ||
      !["7", "8", "9"].includes(phone.toString().charAt(1)) ||
      !["0", "1"].includes(phone.toString().charAt(2))
    ) {
      setPhoneError("Please crosscheck the number you entered");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    if (category === "") {
      setError("You have not picked your qur'ān recitation level.");
      setShowError(true);
      return;
    }

    const formData = {
      firstName,
      lastName,
      dept,
      level,
      phone: phone.trim().split(" ").join(""),
      email,
      category,
      hizb,
      recitation,
      reference,
      date: formattedDate,
    };
    initializePayment({
      config: {
        email,
        firstname: firstName,
        lastname: lastName,
        currency: "NGN",
        metadata: formData,
      },
      onSuccess,
      onClose,
    });
  };

  return (
    <>
      <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
        <h2>Personal Information</h2>
        <div className={styles.info}>
          <div className={fNameError !== "" ? styles.error : null}>
            <p>FIRST NAME</p>
            <input
              type="text"
              value={firstName}
              autoComplete="given-name"
              onChange={(e) => {
                setFNameError("");
                setFirstName(e.target.value);
              }}
              ref={(input) => input && fNameError !== "" && input.focus()}
            />
            <h6>{fNameError}</h6>
          </div>
          <div className={lNameError !== "" ? styles.error : null}>
            <p>LAST NAME</p>
            <input
              type="text"
              value={lastName}
              autoComplete="family-name"
              onChange={(e) => {
                setLNameError("");
                setLastName(e.target.value);
              }}
              ref={(input) => input && lNameError !== "" && input.focus()}
            />
            <h6>{lNameError}</h6>
          </div>
          <div className={deptError !== "" ? styles.error : null}>
            <p>DEPARTMENT</p>
            <DropDown
              prompt="Select Department"
              options={depts}
              onSelect={setDept}
              onClick={() => setDeptError("")}
            />
            <h6>{deptError}</h6>
          </div>
          <div className={levelError !== "" ? styles.error : null}>
            <p>LEVEL</p>
            <DropDown
              prompt="Select Level"
              options={[100, 200, 300, 400, 500, 600]}
              onSelect={setLevel}
              onClick={() => setLevelError("")}
            />
            <h6>{levelError}</h6>
          </div>
          <div className={phoneError !== "" ? styles.error : null}>
            <p>PHONE NUMBER</p>
            <input
              type="tel"
              value={phone}
              autoComplete="tel-national"
              onChange={(e) => {
                setPhoneError("");
                let number = e.target.value
                  ?.replace(/\s+/g, "")
                  .replace(/[^0-9]/g, "")
                  .replace(/(\..*?)\..*/g, "$1");
                number = `${number?.slice(0, 4)}${
                  number.length > 4 ? " " : ""
                }${number?.slice(4, 7)}${
                  number.length > 7 ? " " : ""
                }${number?.slice(7, 11)}`;
                setPhone(number);
              }}
              ref={(input) => input && phoneError !== "" && input.focus()}
            />
            <h6>{phoneError}</h6>
          </div>
          <div className={emailError !== "" ? styles.error : null}>
            <p>EMAIL</p>
            <input
              type="email"
              value={email}
              autoComplete="email"
              onChange={(e) => {
                setEmailError("");
                setEmail(e.target.value);
              }}
              ref={(input) => input && emailError !== "" && input.focus()}
            />
            <h6>{emailError}</h6>
          </div>
        </div>
        <h2>Qur'ān Recitation Level</h2>
        <div className={styles.levels}>
          <div
            className={category === "BEGINNER" ? styles.selected : null}
            onClick={() => {
              setCategory("BEGINNER");
              setHizb("20");
              setRecitation("Suratul An-Naba (Amma) - Suratul An-Nas");
            }}
          >
            <h4>Beginner</h4>
            <p>
              Students in the beginner category will recite from
              <span className={styles.arabic}> سُورَةُ النَّبَأ </span>
              to
              <span className={styles.arabic}> سُورَةُ النَّاس </span>
              (Juz
              <span style={{ visibility: "hidden" }}>i</span>
              Amma) over the course of the year In Shaa Allah
            </p>
          </div>
          <div
            className={category === "INTERMEDIATE" ? styles.selected : null}
            onClick={() => {
              setCategory("INTERMEDIATE");
              setHizb("40");
              setRecitation("Suratul Maryam/Al-Ahqaf - Suratul An-Nas");
            }}
          >
            <h4>Intermediate</h4>
            <p>
              Students in the intermediate category will recite from
              <span className={styles.arabic}> سُورَةُ مَرْيَم </span>
              &#40;or
              <span className={styles.arabic}> سُورَةُ الْأَحْقَاف</span>
              &#41; to
              <span className={styles.arabic}> سُورَةُ النَّاس </span>
              over the course of the year In Shaa Allah
            </p>
          </div>
          <div
            className={category === "ADVANCED" ? styles.selected : null}
            onClick={() => {
              setCategory("ADVANCED");
              setHizb("60");
              setRecitation("Suratul Baqarah/Maryam to Suratul An-Nas");
            }}
          >
            <h4>Advanced</h4>
            <p>
              Students in the advanced category will recite from
              <span className={styles.arabic}> سُورَةُ الْبَقَرَة </span>
              &#40;or
              <span className={styles.arabic}> سُورَةُ مَرْيَم</span>
              &#41; to
              <span className={styles.arabic}> سُورَةُ النَّاس </span>
              over the course of the year In Shaa Allah
            </p>
          </div>
        </div>
        <h2 style={{ marginBottom: "0" }}>NOTE</h2>
        <div>
          <ol>
            <li>
              The Islamiyyah timetable is as follows:
              <ul>
                <li>
                  Thursdays 8p.m. to 9:30p.m. - Qur'ān and Tajwīd &#40;and Hurūf
                  for beginner class&#41;
                </li>
                <li>Fridays 8p.m. to 9:30p.m. - Hadīth, Fiqh, and 'Aqīdah</li>
                <li>Venue: Ground floor, Niger Block</li>
              </ul>
            </li>
            <li>
              You are required to pay ₦3,000 enrollment fee in other to proceed
              with the registration.
            </li>
            <li>
              After Registration is done, the following books will be provided:
              <ul>
                <li>Usūl-uth-thalātha &#40;for 'Aqīdah&#41;</li>
                <li>Haqq-ul-mubīn &#40;for Hadīth&#41;</li>
                <li>Fiqh al-Akhdari &#40;for Fiqh&#41;</li>
                <li>Kitab-ut-tajwīd &#40;for Tajwīd&#41;</li>
              </ul>
            </li>
            <li>
              You can join the Islamiyyah WhatsApp group by clicking&nbsp;
              <a href="/">here</a>.
            </li>
          </ol>
          <p className={styles.condition}>
            {agreed ? (
              <img
                src="./images/icons/checkbox-checked.svg"
                alt=""
                onClick={() => setAgreed(false)}
              />
            ) : (
              <img
                src="./images/icons/checkbox-unchecked.svg"
                alt=""
                style={{
                  height: "18px",
                  marginLeft: "3.5px",
                  marginRight: "3.5px",
                }}
                onClick={() => setAgreed(true)}
              />
            )}
            I agree to abide by the Islamiyah guidelines to the best of my
            ability.
          </p>
        </div>
        <div className={styles.submitButton}>
          <button
            disabled={!agreed}
            onClick={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            Register
          </button>
        </div>
        <Overlay show={showError}>
          <div style={{ textAlign: "center" }}>{error}</div>
        </Overlay>
      </form>
    </>
  );
}
