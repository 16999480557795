import AuthPage from "../components/AuthPage/AuthPage";
import FP1 from "../components/FP1/FP1";
// import FP2 from "../components/FP2/FP2";
import FP3 from "../components/FP3/FP3";

export default function ForgotPassword(props) {

    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const oobCode = urlParams.get('oobCode');
    const continueUrl = urlParams.get('continueUrl');
    const email = continueUrl? continueUrl.match(/email=([^&]+)/)[1] : "";

    return (
        <AuthPage title="Forgot Password">
            {
                mode !== 'resetPassword' ?
                    <FP1 /> :
                    <FP3 code={oobCode} email={email} />
                // <FP2 back={() => setPage(1)} next={() => setPage(3)} /> : 
            }
        </AuthPage>
    );
}
