import { useState } from "react";
import styles from "../AuthPage/AuthPage.module.css";
import { validateEmail } from "../../utils/validate";
import { hash } from "../../utils/compute";
import { useAuth } from "../../hooks/auth";
import { addUser, checkUser, proceedWithOTP } from "../../firebase/firestore";
import { useNext } from "../../hooks/nextPage";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { setLoggedIn } from "../../redux/slices/newUser";
import { useDispatch } from "react-redux";

export default function CA1(props) {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const { user, updateData } = useUser();
  const { signup, signInWithGoogle } = useAuth();

  const [fullName, setFullName] = useState(user.fullName);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [fNameError, setFNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [pwError, setPwError] = useState("");
  const [cpwError, setCpwError] = useState("");

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const nextPage = useNext("/sign-up", { signup: "final" });
  const nextPageOtp = useNext("/sign-up", { signup: "otp" });

  const submit = async () => {
    setFNameError("");
    setEmailError("");
    setPwError("");
    setCpwError("");

    if (fullName.length === 0) {
      setFNameError("Please enter your full name");
      return;
    }

    if (fullName?.split(" ").length < 2) {
      setFNameError("Please enter your full name");
      return;
    }

    if (email.length === 0) {
      setEmailError("Please enter your email address");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    if (password.length === 0) {
      setPwError("Please enter a password");
      return;
    }

    if (password.length < 8) {
      setPwError("Your password should be at least 8 characters");
      return;
    }

    if (password !== confirmPassword) {
      setCpwError("Passwords do not match");
      return;
    }

    try {
      setLoading(true);
      const passwordHash = await hash(password);
      const newUser = await signup(email, password);
      if (newUser) {
        const data = {
          ...user,
          fullName: fullName,
          email: email,
          uid: newUser.uid,
          provider: "email",
          userRole: "USER",
          passwordHash,
        };

        await addUser(newUser.uid, { ...data });
        await proceedWithOTP(newUser.uid, email);
        setLoading(false);
        updateData(data);
        nextPageOtp();
        return;
      } else {
        throw new Error("An error occurred while creating new user");
      }
    } catch (e) {
      setLoading(false);
      if (e.code === "auth/email-already-in-use") {
        setEmailError("Email already in use");
      } else {
        console.error(e);
        alert(e.message);
      }
    }
  };

  const googleSignin = async () => {
    try {
      setLoading(true);
      const newUser = await signInWithGoogle();
      if (newUser) {
        const { exists, userData: userDb } = await checkUser(newUser.email);
        if (exists) {
          updateData({
            ...userDb,
            provider: "google",
            photoURL: newUser.photoURL,
          });
          dispatch(setLoggedIn());
        } else {
          const userData = {
            fullName: newUser.displayName,
            email: newUser.email,
            uid: newUser.uid,
            photoURL: newUser.photoURL,
            provider: "google",
            verified: true,
          };
          const data = { ...user, ...userData, userRole: "USER" };
          await addUser(newUser.uid, data);
          updateData(data);
          nextPage();
        }
      } else {
        throw new Error("Missing email or password.");
      }
    } catch (e) {
      if (e.code === "auth/email-already-in-use") {
        setEmailError("Email already in use");
      } else if (e.code === "auth/popup-blocked") {
        alert(
          "Popup blocked! please try again.\nIf this error still remain check your browser settings and enable popup."
        );
      } else if (e.message !== "user-already-exists") {
        console.error(e);
        alert(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p className={styles.back} onClick={() => navigate("/")}>
        Home
      </p>
      <form className={styles.form}>
        <h3>Main Information (1/3)</h3>
        <div className={fNameError !== "" ? styles.error : null}>
          <p>Full Name</p>
          <input
            type="text"
            value={fullName}
            placeholder="Enter your full name"
            autoComplete="name"
            onChange={(e) => {
              setFNameError("");
              setEmailError("");
              setPwError("");
              setCpwError("");
              setFullName(e.target.value);
              if (e.target.value.length === 0) {
                setFNameError("Please enter your full name");
                return;
              }

              if (e.target.value?.split(" ").length < 2) {
                setFNameError("Please enter your full name");
                return;
              }
            }}
            ref={(input) => input && fNameError !== "" && input.focus()}
          />
          <h6>{fNameError}</h6>
        </div>
        <div className={emailError !== "" ? styles.error : null}>
          <p>Email</p>
          <input
            type="email"
            value={email}
            placeholder="Enter your email address"
            autoComplete="email"
            onChange={(e) => {
              setFNameError("");
              setEmailError("");
              setPwError("");
              setCpwError("");
              setEmail(e.target.value);
              if (e.target.value.length === 0) {
                setEmailError("Please enter your email address");
                return;
              } else if (!validateEmail(e.target.value)) {
                setEmailError("Please enter a valid email address");
                return;
              }
            }}
            ref={(input) => input && emailError !== "" && input.focus()}
          />
          <h6>{emailError}</h6>
        </div>
        <div className={pwError !== "" ? styles.error : null}>
          <p>Password</p>
          <div className={styles.password}>
            <input
              type={visible ? "text" : "password"}
              value={password}
              placeholder="Enter your password"
              autoComplete="current-password"
              onChange={(e) => {
                setFNameError("");
                setEmailError("");
                setPwError("");
                setCpwError("");
                setPassword(e.target.value);
              }}
              ref={(input) => input && pwError !== "" && input.focus()}
            />
            <div onClick={() => setVisible(!visible)}>
              {visible ? (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/hide-password.png"
                  alt="visible"
                />
              ) : (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/show-password.png"
                  alt="visible"
                />
              )}
            </div>
          </div>
          <h6>{pwError}</h6>
        </div>
        <div className={cpwError !== "" ? styles.error : null}>
          <p>Confirm Password</p>
          <div className={styles.password}>
            <input
              type={visible ? "text" : "password"}
              value={confirmPassword}
              placeholder="Confirm password"
              autoComplete="new-password"
              onChange={(e) => {
                setFNameError("");
                setEmailError("");
                setPwError("");
                setCpwError("");
                setConfirmPassword(e.target.value);
              }}
              ref={(input) => input && cpwError !== "" && input.focus()}
            />
            <div onClick={() => setVisible(!visible)}>
              {visible ? (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/hide-password.png"
                  alt="visible"
                />
              ) : (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/show-password.png"
                  alt="visible"
                />
              )}
            </div>
          </div>
          <h6>{cpwError}</h6>
        </div>
      </form>
      {/* <div><span>By clicking Sign Up, I agree to Cloudflare's </span><a href="" target="_blank" class=""><span>terms</span></a><span>, </span><a href="" target="_blank" class=""><span>privacy policy</span></a><span>, and </span><a href="" target="_blank" class=""><span>cookie policy</span></a><span>.</span></div> */}
      {/* By registering you declare that you accept the terms and conditions of use , as well as the Privacy Policy and the Cookies Policy. */}
      {loading ? (
        <button className={styles.loader2}></button>
      ) : (
        <>
          <button onClick={() => submit()}>Next</button>
          <button className={styles.google} onClick={() => googleSignin()}>
            <img src="./images/icons/google.svg" alt="google-icon"></img>Sign up
            with Google
          </button>
        </>
      )}
      <p>
        Already have an account? <a href="/login">Sign In</a>
      </p>
    </>
  );
}
