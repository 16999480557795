import styles from './Event.module.css';

export default function Event(props) {
    return (
        <div className={`${styles.event} ${props.next ? styles.next : null}`}>
            <h3>{props.title}</h3>
            <p>{props.brief}</p>
        </div>
    );
}
