import { useEffect, useState } from "react";
import styles from "../AuthPage/AuthPage.module.css";
import { useNext } from "../../hooks/nextPage";
import { toMins } from "../../utils/compute";
import { sendUserOTP, updateUser, verifyOTP } from "../../firebase/firestore";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";

export default function CA2(props) {
  const navigate = useNavigate();
  const {user, updateData} = useUser();

  const [currentDigit, setCurrentDigit] = useState(1);
  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");

  const [timeLeft, setTimeLeft] = useState(120);
  const [loading, setLoading] = useState(false);

  const nextPage = useNext("/sign-up", { signup: "final" });

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  // Use useEffect to trigger an action when currentDigit reaches 5
  useEffect(() => {
    async function handleOTP() {
      if (currentDigit === 5 && timeLeft > 0) {
        const OTP = {
          digit1,
          digit2,
          digit3,
          digit4,
        };

        try {
          setLoading(true);
          const verified = await verifyOTP(
            user.uid || user.email,
            Object.values(OTP).join("")
          );
          if (verified) {
            updateData({
              ...user,
              verified: true,
            });
            await updateUser(user.uid, { verified: true });
            setLoading(false);
            nextPage();
          } else {
            throw new Error("Verification Failed!");
          }
        } catch (error) {
          setLoading(false);
          setDigit1("");
          setDigit2("");
          setDigit3("");
          setDigit4("");
          setCurrentDigit(1);
          console.error(error);
        }
      }
    }

    handleOTP();
  }, [currentDigit]); // eslint-disable-line react-hooks/exhaustive-deps

  const sendAgain = async () => {
    // Generate new code and send
    setLoading(true);
    sendUserOTP(user.email)
      .then(() => {
        setLoading(false);
        console.log("New OTP Sent!");
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    setTimeLeft(120);
    setDigit1("");
    setDigit2("");
    setDigit3("");
    setDigit4("");
    setCurrentDigit(1);
  };

  const handleKeyDown = (event, digit) => {
    if (event.key === "Backspace") {
      event.preventDefault();
      if (digit === 2) {
        setDigit1("");
        setCurrentDigit((prev) => prev - 1);
      } else if (digit === 3) {
        setDigit2("");
        setCurrentDigit((prev) => prev - 1);
      } else if (digit === 4) {
        setDigit3("");
        setCurrentDigit((prev) => prev - 1);
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text/plain");

    // Validate the pasted text
    if (/\d{4}/.test(pastedText)) {
      const digitsArray = pastedText.split("");
      setDigit1(digitsArray[0]);
      setDigit2(digitsArray[1]);
      setDigit3(digitsArray[2]);
      setDigit4(digitsArray[3]);
      setCurrentDigit(5);
    }
  };

  return (
    <>
      <p className={styles.back} onClick={() => navigate("/")}>
        Home
      </p>
      <form className={styles.form}>
        <h3>OTP Verification (2/3)</h3>
        <h4>
          <span className={styles.blue}>Hello{props.name}!</span> Help us make
          sure it's you by verifying your email.
        </h4>
        <h5>Enter the 4-digit OTP we sent to your mail</h5>
        <div className={styles.otp}>
          <input
            type="tel"
            maxLength={1}
            value={digit1}
            onChange={(e) => {
              let number = e.target.value
                ?.replace(/\s+/g, "")
                .replace(/[^0-9]/g, "")
                .replace(/(\..*?)\..*/g, "$1");
              setDigit1(number);
              if (currentDigit < 5 && number !== "") setCurrentDigit(2);
            }}
            onPaste={handlePaste}
            ref={(input) => input && currentDigit === 1 && input.focus()}
          />
          <input
            type="tel"
            maxLength={1}
            value={digit2}
            onChange={(e) => {
              let number = e.target.value
                ?.replace(/\s+/g, "")
                .replace(/[^0-9]/g, "")
                .replace(/(\..*?)\..*/g, "$1");
              if (currentDigit < 5 && number !== "" && digit1 !== "") {
                setDigit2(number);
                setCurrentDigit(3);
              } else {
                setCurrentDigit(2);
              }
            }}
            onKeyDown={(event) => {
              handleKeyDown(event, 2);
            }}
            ref={(input) => input && currentDigit === 2 && input.focus()}
          />
          <input
            type="tel"
            maxLength={1}
            value={digit3}
            onChange={(e) => {
              let number = e.target.value
                ?.replace(/\s+/g, "")
                .replace(/[^0-9]/g, "")
                .replace(/(\..*?)\..*/g, "$1");
              if (currentDigit < 5 && number !== "" && digit2 !== "") {
                setDigit3(number);
                setCurrentDigit(4);
              } else {
                setCurrentDigit(3);
              }
            }}
            onKeyDown={(event) => {
              handleKeyDown(event, 3);
            }}
            ref={(input) => input && currentDigit === 3 && input.focus()}
          />
          <input
            type="tel"
            maxLength={1}
            value={digit4}
            onChange={(e) => {
              let number = e.target.value
                ?.replace(/\s+/g, "")
                .replace(/[^0-9]/g, "")
                .replace(/(\..*?)\..*/g, "$1");
              if (currentDigit < 5 && number !== "" && digit3 !== "") {
                setDigit4(number);
                setCurrentDigit(5);
              } else {
                setCurrentDigit(4);
              }
            }}
            onKeyDown={(event) => {
              handleKeyDown(event, 4);
            }}
            ref={(input) => input && currentDigit === 4 && input.focus()}
          />
        </div>
        <h5>
          {timeLeft > 0 ? (
            <>
              The code will expire in{" "}
              <b className={styles.blue}>{toMins(timeLeft)}</b>
            </>
          ) : (
            <>The code has expired. Click the button below to send again.</>
          )}
        </h5>
      </form>
      {loading ? (
        <button className={styles.loader1}></button>
      ) : (
        <button onClick={() => sendAgain()}>Send Again</button>
      )}
    </>
  );
}
