import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "./hooks/auth";
import { setLoggedOut } from "./redux/slices/newUser";
import MainRoutes from "./routes/MainRoutes";
import MaintenanceRoutes from "./routes/MaintenanceRoutes";
import { Helmet } from "react-helmet";

export default function App() {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  let loggedIn = useSelector((state) => state.newUser.loggedIn);

  const [underMaintenance, setUnderMaintenance] = useState(false); // eslint-disable-line no-unused-vars

  if (!currentUser) {
    dispatch(setLoggedOut());
  }

  return (
    <>
      <Helmet>
        <title>Nile MSSN</title>
        <meta name="description" content="Nile MSSN Website" />
        <meta name="keywords" content="nilemssn, mssn, nileMSSN" />
        <meta name="author" content="NileMSSN" />
        <meta property="og:title" content="Nile MSSN" />
        <meta
          property="og:description"
          content="Welcome to Nile MSSN! A journey of spiritual upliftment, inspired by ..."
        />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_HOSTNAME}/images/logo/2.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_HOSTNAME}/`}
        />
        {/* <meta name="twitter:title" content="My Page Title" />
      <meta
        name="twitter:description"
        content="This is a description of my page"
      />
      <meta name="twitter:image" content="https://example.com/image.jpg" />
      <meta name="twitter:card" content="summary_large_image" /> */}
      </Helmet>
      {underMaintenance ? (
        <MaintenanceRoutes />
      ) : (
        <MainRoutes loggedIn={loggedIn} />
      )}
      ;
    </>
  );
}
