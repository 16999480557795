/* UTILITY FUNCTIONS
--------------------------------------------------------------
*/
export const isEmail = (email) => {
  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateEmail = (email) => {
  return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

/**
 * Validates user data to ensure it contains a valid 'uid' and 'email'.
 *
 * @param {Object} data - The user data to be validated.
 * @returns {Object | null} - The validated user data if valid, otherwise null.
 * @throws {Error} - If 'uid' or 'email' is missing or 'uid' is not a non-empty string.
 */
export const validateUserData = (data) => {
  // Check if the input is an object
  if (typeof data !== "object" || data === null) {
    return null;
  }

  // Check if uid and email properties exist and are valid
  if (
    !Object.prototype.hasOwnProperty.call(data, "uid") ||
    !Object.prototype.hasOwnProperty.call(data, "email") ||
    typeof data.uid !== "string" ||
    data.uid.trim() === "" ||
    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)
  ) {
    throw new Error(
      "Both uid and email are required, and uid must be a non-empty string!"
    );
  }
  return data;
};
