import { hash, generateOTP } from "../utils/compute";
import { isEmail, validateUserData } from "../utils/validate";
import { db } from "./config";
import {
  collection,
  getDocs,
  deleteDoc,
  getDoc,
  setDoc,
  doc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";

/* USER FUNCTIONS
--------------------------------------------------------------
*/

/**
 * Retrieves user data from the database based on the provided user ID.
 *
 * @param {string} userId - The ID of the user to retrieve data for.
 * @returns {Array<Object> | null} An array of user data objects if users are found, otherwise null.
 */
export async function getUser(userId) {
  try {
    const userColRef = collection(db, "users");
    const q = query(
      userColRef,
      isEmail(userId)
        ? where("email", "==", userId)
        : where("uid", "==", userId)
    );
    const querySnapshot = await getDocs(q);
    const users = [];
    const userIds = new Set();

    if (!querySnapshot.empty) {
      querySnapshot.docs.forEach((doc) => {
        if (!userIds.has(doc.id)) {
          users.push(doc.data());
          userIds.add(doc.id);
        }
      });
    } else {
      return null;
    }

    return users; // Return users array or null
  } catch (error) {
    console.error("Error fetching users:", error);
    return null; // Return null in case of errors
  }
}

/**
 * Checks if a user exists in the database based on the provided userId.
 * @param {string} userId - The unique identifier of the user to check.
 * @returns {Object} An object containing a boolean 'exists' field indicating if the user exists,
 * and the 'userData' field with user Data if the user exists, otherwise 'null'.
 */
export const checkUser = async (userId) => {
  const userData = await getUser(userId);
  if (userData) {
    return { exists: true, userData: userData[0] };
  } else {
    return { exists: false, userData: null };
  }
};

/**
 * Adds a new user to the Firestore database if the user does not already exist.
 *
 * @param {string} userId - The unique identifier for the user.
 * @param {object} userData - The data of the user to be added.
 * @returns {Promise<void>} A promise that resolves with the newly added user information if successful,
 * or null if the user already exists.
 * @throws {Error} If there is an error adding the user to the database.
 */
export const addUser = async (uid, userData) => {
  const { exists } = await checkUser(uid);

  if (!exists) {
    const userRef = doc(db, "users", uid);
    const formattedUserData = { ...userData, uid };
    await setDoc(userRef, validateUserData(formattedUserData));
    console.log("User added successfully");
  } else {
    throw new Error("user-already-exists");
  }
};

/**
 * Updates a user's profile data in the database.
 *
 * @param {string} uid - The ID of the user to update.
 * @param {Object} userData - The updated user data to be stored.
 * @returns {Promise<Object>} - The updated user document if successful.
 */
export const updateUser = async (uid, userData) => {
  const { exists } = await checkUser(uid);

  if (exists) {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, { ...userData, uid });
    console.log("user updated");
    return;
  } else {
    throw new Error("Error updating user");
  }
};

export const deleteUser = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    const { exists } = await checkUser(userId);

    if (exists) {
      console.log("user found!");
      deleteDoc(userRef).then(() => {
        alert("user deleted!");
      });
      console.log("user deleted!");
    } else {
      alert("User not found!");
    }
  } catch (err) {
    console.error("Error deleting user: ", err.message);
  }
};

/**
 * Retrieves the email from the provided user data.
 *
 * @param {Array} userData - The user data array.
 * @returns {string|null} The email extracted from the user data, or null if the array is empty or the email is not found.
 */
export const getEmailFromUserData = (userData) => {
  if (Array.isArray(userData) && userData.length > 0) {
    const userEmail = userData?.email;
    return userEmail;
  }
  return null;
};

/**
 * Retrieves the user's id from the provided email.
 *
 * @param {string} email - The user email.
 * @returns {string|null} The id of the user, or null if the id is not found.
 */
export const getIdFromEmail = async (email) => {
  try {
    const userData = await getUser(email);
    if (Array.isArray(userData) && userData.length > 0) {
      const userId = userData?.uid;
      return userId;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * Retrieves the email from the provided user id.
 *
 * @param {string} uid - The user id.
 * @returns {string|null} The email of the user, or null if the email is not found.
 */
export const getEmailFromid = async (uid) => {
  try {
    const userData = await getUser(uid);
    return getEmailFromUserData(userData);
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
   * Sends the body of an email to user email.
   * 
   * @param {string} email - The email address of the user.
   * @param {object} body - The email body object.
   * @returns {boolean} True if email sent, otherwise false.
   * 
   * @example
   *   const body = {
      to: "attahir.nasir@gmail.com",
      template: {
        name: "welcome",
        data: {
          userName: "Attahiru",
          phoneNumber: "09024061250"
        }
      }
    }
   */
export const sendEmail = async (body) => {
  try {
    const emailRef = doc(db, "emails", body.to);
    await setDoc(emailRef, body);
    console.log("Email queued for delivery!");
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const sendOTPEmail = async (email, otp) => {
  try {
    const emailBody = {
      to: email,
      template: {
        name: "otp",
        data: {
          otp_code: otp,
          expiration_time: 2,
          app_name: "Nile MSSN",
        },
      },
    };

    const status = await sendEmail(emailBody);
    if (status) {
      console.log(`OTP sent to ${email}!`);
      return true;
    } else {
      throw new Error("Failed to send OTP. Please try again later.");
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const proceedWithOTP = async (uid, email) => {
  // generate the opt
  const otp = generateOTP();

  // Save otp to database
  await updateUser(uid, { otp });

  // send the otp as email
  const status = await sendOTPEmail(email, otp);
  if (status) {
    return;
  } else {
    throw new Error("Failed to send OTP. Please try again later.");
  }
};

/**
 * Resends OTP to the user's email after generating a new OTP.
 *
 * @param {string} email - The email address of the user to resend OTP.
 * @returns {Boolean} - Returns 'successful' if OTP is sent successfully.
 * @throws {Error} - If there is an error in resending OTP or user not found.
 */
export const sendUserOTP = async (email) => {
  const otp = generateOTP();
  const { exists, userData } = await checkUser(email);

  if (exists) {
    await updateUser(userData.uid, { otp });

    const status = await sendOTPEmail(email, otp);
    if (status) {
      return status;
    } else {
      throw new Error("Failed to send OTP. Please try again later.");
    }
  }
};

export const updateUserPassword = async (email, password) => {
  try {
    const passwordHash = await hash(password);
    const { userData } = await checkUser(email);
    await updateUser(userData.uid, { passwordHash });
    return passwordHash;
  } catch (error) {
    throw new Error("Failed to update user password!");
  }
};

export const verifyOTP = async (email, code) => {
  const { exists, userData } = await checkUser(email);
  if (exists) {
    const sentOTPCode = userData?.otp;
    if (code === sentOTPCode) {
      return true;
    } else {
      return false;
    }
  } else {
    throw new Error("User not found");
  }
};

export async function getPrayerTimes() {
  try {
    const prayerTimesDocRef = doc(db, "app-state", "prayer-times");
    const snapshot = await getDoc(prayerTimesDocRef);
    return snapshot.data() || null;
  } catch (error) {
    console.error("Error getting prayer times:", error);
    return null;
  }
}

export const getCharityEvents = async (num) => {
  const EventsCollectionRef = collection(db, "events");
  const q = query(EventsCollectionRef, where("eventType", "==", "charity"));
  const querySnapshot = await getDocs(q);

  const charityEvents = [];

  if (!querySnapshot.empty) {
    querySnapshot.docs.forEach((doc, index) => {
      const data = doc.data();
      const id = doc.id;
      charityEvents.push({ ...data, id });
      if (index === num) return;
    });
    return charityEvents || null;
  } else {
    return null;
  }
};

export const registerIslamiyyaStudent = async (uid, formData) => {
  const { exists } = await checkUser(uid);

  if (!exists) {
    throw new Error("Unauthorized-user");
  }

  const formRef = doc(
    db,
    "Islamiyya-students",
    `${formData.firstName.trim().toUpperCase()}_${formData.lastName
      .trim()
      .toUpperCase()
      .replace(" ", "_")}`
  );
  await setDoc(formRef, formData);
};
