import { useState } from "react";
import styles from "../AuthPage/AuthPage.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useUser } from "../../hooks/useUser";
import { checkUser } from "../../firebase/firestore";
import { setLoggedIn } from "../../redux/slices/newUser";
import { useDispatch } from "react-redux";

export default function FP3(props) {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const { user, updateData } = useUser();
  const { handleResetPassword } = useAuth();

  const email = props.email || user.email;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [pwError, setPwError] = useState("");
  const [cpwError, setCpwError] = useState("");

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const submit = async () => {
    if (password.length === 0) {
      setPwError("Please enter a password");
      return;
    }

    if (password.length < 8) {
      setPwError("Your password should be at least 8 characters");
      return;
    }

    if (password !== confirmPassword) {
      setCpwError("Passwords do not match");
      return;
    }

    setLoading(true);
    try {
      const { exists, userData } = await checkUser(email);
      if (exists) {
        const passwordHash = await handleResetPassword(
          email,
          password,
          props.code
        );
        updateData({ ...user, ...userData, passwordHash });
        dispatch(setLoggedIn());
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p className={styles.back} onClick={() => navigate("/login")}>
        Back
      </p>
      <form className={styles.form}>
        <div className={pwError !== "" ? styles.error : null}>
          <p>Password</p>
          <div className={styles.password}>
            <input
              type={visible ? "text" : "password"}
              value={password}
              placeholder="Enter your password"
              onChange={(e) => {
                setPwError("");
                setPassword(e.target.value);
              }}
              ref={(input) => input && pwError !== "" && input.focus()}
            />
            <div onClick={() => setVisible(!visible)}>
              {visible ? (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/hide-password.png"
                  alt="visible"
                />
              ) : (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/show-password.png"
                  alt="visible"
                />
              )}
            </div>
          </div>
          <h6>{pwError}</h6>
        </div>
        <div className={cpwError !== "" ? styles.error : null}>
          <p>Confirm Password</p>
          <div className={styles.password}>
            <input
              type={visible ? "text" : "password"}
              value={confirmPassword}
              placeholder="Confirm password"
              onChange={(e) => {
                setCpwError("");
                setConfirmPassword(e.target.value);
              }}
              ref={(input) => input && cpwError !== "" && input.focus()}
            />
            <div onClick={() => setVisible(!visible)}>
              {visible ? (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/hide-password.png"
                  alt="visible"
                />
              ) : (
                <img
                  width="22"
                  height="22"
                  src="./images/icons/show-password.png"
                  alt="visible"
                />
              )}
            </div>
          </div>
          <h6>{cpwError}</h6>
        </div>
      </form>
      {loading ? (
        <button className={styles.loader1}></button>
      ) : (
        <button onClick={() => submit()}>Reset</button>
      )}
    </>
  );
}
