import Hero from "../components/Hero/Hero";
import DailyQuran from "../components/DailyQuran/DailyQuran";
import DailyHadith from "../components/DailyHadith/DailyHadith";
import DailyDua from "../components/DailyDua/DailyDua";
// import FundRaiser from "../components/fundRaiser/fundRaiser";
import OurActivites from "../components/OurActivities/OurActivities";
import { useEffect } from "react";

export default function Home(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero name={typeof props.name !== "undefined" ? `, ${props.name}` : ""} />
      <OurActivites />
      {/* <FundRaiser /> */}
      <DailyQuran />
      <DailyHadith />
      <DailyDua />
    </>
  );
}
