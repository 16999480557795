import { useState } from "react";
import styles from "./DropDown.module.css";

export default function DropDown(props) {
  const [showOptions, setShowOptions] = useState(false);
  const [chosen, setChosen] = useState(props.prompt);

  return (
    <div className={styles.dropdown} onClick={props.onClick}>
      <div
        className={styles.chosen}
        onClick={() => setShowOptions(!showOptions)}
      >
        {chosen}
        <img
          src="./images/icons/chevron-down.svg"
          alt=""
          style={
            showOptions
              ? { transform: "rotate(180deg)", transition: "transform 0.2s" }
              : null
          }
        />
      </div>
      {showOptions && (
        <div
          onMouseLeave={() => setShowOptions(false)}
          className={styles.options}
          style={{
            height: props.height
              ? `${props.height}px`
              : props.options.length > 4
              ? "190px"
              : `${props.options.length * 3 + 1}px`,
          }}
        >
          {props.options.map((option, index) => {
            return (
              <div
                key={index}
                className={styles.option}
                onClick={() => {
                  props.onSelect(option);
                  setChosen(option);
                  setShowOptions(false);
                }}
              >
                {option}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
