import { useEffect } from "react";
import IslamiyyaForm from "../components/IslamiyyaForm/IslamiyyaForm";
import styles from "../components/IslamiyyaForm/IslamiyyaForm.module.css";

export default function IslamiyyaRegistration() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className={styles.main}>
            <h1 className={styles.heading}>Islamiyyah Registration</h1>
            <IslamiyyaForm />
        </div>
    );
}
