import AboutUsContent from "../AboutUsContent/AboutUsContent";

export default function OurService() {
    return (
        <AboutUsContent left={false} title="Our Service" img="./images/backgrounds/our-service.png">
            At Nile MSSN, we are committed to providing a wide range of services that cater to the diverse needs of our students and community. Our comprehensive approach ensures that every student receives the support and opportunities they need to succeed.<br/>
            Our religious education program is designed to instill strong Islamic values and knowledge in our students. We offer comprehensive Quranic studies, Hadith classes, and other religious instruction to help students deepen their understanding and practice of Islam.
        </AboutUsContent>
    );
}
