import { useEffect } from 'react';
import Event from '../components/Event/Event';
import styles from '../components/Event/Event.module.css';

export default function Programs(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className={styles.main}>
            <div className={styles.mobileFixed}>
                <h1 className={styles.heading}>Events</h1>
                <div className={styles.buttons}>
                    <button>Past Events</button>
                    <button>Upcoming Events<span>1</span></button>
                </div>
            </div>
            <div className={styles.events}>
                <Event 
                    title="Dawah Session"
                    brief="We have a dawah session coming up at the ending of the month. 27 June 2024 at the main mosque."
                    next={true}
                />
                <Event 
                    title="Dawah Session"
                    brief="We have a dawah session coming up at the ending of the month. 27 June 2024 at the main mosque."
                    next={false}
                />
                <Event 
                    title="Dawah Session"
                    brief="We have a dawah session coming up at the ending of the month. 27 June 2024 at the main mosque."
                    next={false}
                />
            </div>
        </div>
    );
}
